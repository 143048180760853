import React, { useState, useEffect } from "react"
import { graphql, StaticQuery } from "gatsby"
import window from "global"
import { Swipeable } from "react-swipeable"
/* import { useSwipeable, Swipeable } from 'react-swipeable'    ATTENTION: we removed the useSwipeable from the line above to prevent warning */
import styles from "./component.module.css"
import BgImg from "../../images/dw-pattern-outline.png"

const OurProjects = props => {
  const [count, setCount] = useState(0)

  const [max, setMax] = useState(0)
  const [displayButton, setDisplayButton] = useState(true)
  let num = 0

  function nextClickHandler() {
    if (window.innerWidth >= 2350) {
      count < max - 3 ? setCount(count + 1) : setDisplayButton(false)
    }
    if (window.innerWidth < 2350 && window.innerWidth > 2150) {
      count < max - 2 ? setCount(count + 1) : setDisplayButton(false)
    }
    if (window.innerWidth < 2149 && window.innerWidth > 1550) {
      count < max - 2 ? setCount(count + 1) : setDisplayButton(false)
    }
    if (window.innerWidth < 1550) {
      count < max - 1 ? setCount(count + 1) : setDisplayButton(false)
    }
  }

  function prevClickHandler() {
    if (count >= 1) {
      setCount(count - 1)
      setDisplayButton(true)
    }
  }

  useEffect(() => {
    setMax(num)
  }, [num])

  const [displaySlider, setDisplaySlider] = useState(true)
  useEffect(() => {
    if (num < 2) {
      setDisplaySlider(false)
    }
  }, [num])
  return (
    <StaticQuery
      query={graphql`
        query MyQuery {
          allDatoCmsReferenz(limit: 6) {
            nodes {
              id
              kategorie {
                name
              }
              kundeKurz
              slug
              teaserText
              website
              titelDerReferenz
              technologie
              teaserbild {
                alt
                fluid(imgixParams: { auto: "compress" }) {
                  src
                }
              }
            }
          }
        }
      `}
      render={data => (
        <div className={styles.componentContainer}>
          <span style={{ display: "none" }}>
            {(num = data.allDatoCmsReferenz.nodes.length)}
          </span>
          <div
            className={styles.topContainer}
            style={props.page === "refrenz" ? { marginTop: "100px" } : {}}
          >
            <h2 className={styles.h1}>Unsere Projekte</h2>
            {displaySlider && (
              <div className={styles.slider}>
                <button
                  aria-label="Previouse item"
                  className={`${styles.leftArrow} ${
                    count > 0 ? styles.activeLeftArrow : ""
                  }`}
                  onClick={prevClickHandler}
                ></button>
                <button
                  aria-label="Next item"
                  className={`${styles.rightArrow} ${
                    !displayButton ||( count === max - 1 ) ? styles.opacityOn : ""
                  }`}
                  onClick={nextClickHandler}
                ></button>
              </div>
            )}
          </div>
          <Swipeable
            onSwipedRight={prevClickHandler}
            onSwipedLeft={nextClickHandler}
          >
            <div className={`${styles.projectsContainer}`}>
              <div className={styles.bgImg}>
                <img src={BgImg} alt="bgimg" />
              </div>
              <ol
                style={{
                  transform:
                    "translateX(" +
                    (100 / data.allDatoCmsReferenz.nodes.length) * -1 * count +
                    "%)",
                }}
              >
                {data.allDatoCmsReferenz.nodes.map((item, index) => {
                  return (
                    <li className={`${styles.projectItem}`} key={index}>
                      <a href={"/" + item.slug + "/"}>
                        {item.teaserbild.fluid && (
                          <img src={item.teaserbild.fluid.src} alt={item.teaserbild.alt} />
                        )}
                        <span className={styles.projectThechnologies}>
                          {item.kategorie.map((names, index, arr) => {
                            if (arr.length - 1 === index) {
                              return names.name
                            } else {
                              return names.name + " / "
                            }
                          })}
                        </span>
                        <h3 className={styles.h2}>
                          {item.kundeKurz}
                          <span></span>
                        </h3>
                        <p>{item.teaserText}</p>
                      </a>
                    </li>
                  )
                })}
              </ol>
            </div>
          </Swipeable>
        </div>
      )}
    />
  )
}

export default OurProjects
